import * as R from 'ramda';
import {formatLocale} from 'd3-format';
import isNumeric from 'fast-isnumeric';
import {isNil} from 'lodash';

/* Lifted from
 * https://github.com/plotly/dash/blob/2975e001cf017919929b0ebad1f1d1e14fa32f93/components/dash-table/src/dash-table/type/null.ts
 * with minor changes to make linters happy.
 */

export const isNully = (value) =>
    isNil(value) ||
    (typeof value === 'number' && (isNaN(value) || !isFinite(value)));

export const reconcileNull = (value, options) => {
    const allowNull = Boolean(
        options && options.validation && options.validation.allow_null
    );
    const nully = isNully(value);

    return {
        success: nully && allowNull,
        value: nully ? null : value,
    };
};

/* Lifted from:
 * https://github.com/plotly/dash/blob/2975e001cf017919929b0ebad1f1d1e14fa32f93/components/dash-table/src/dash-table/type/number.ts
 * with minor changes to make linters happy.
 */

const convertToD3 = ({group, symbol, ...others}) => ({
    currency: symbol,
    thousands: group,
    ...R.omit(['separate_4digits', 'symbol'], others),
});

export function coerce(value, options) {
    return isNumeric(value)
        ? {success: true, value: Number(value)}
        : reconcileNull(value, options);
}

export function getFormatter(format) {
    if (!format) {
        return (value) => value;
    }

    const locale = formatLocale(convertToD3(format.locale));

    const numberFormatter = format.prefix
        ? locale.formatPrefix(format.specifier, format.prefix)
        : locale.format(format.specifier);

    const thousandsSpecifier = format.locale.separate_4digits
        ? format.specifier
        : format.specifier.replace(/,/, '');

    const thousandsFormatter = format.prefix
        ? locale.formatPrefix(thousandsSpecifier, format.prefix)
        : locale.format(thousandsSpecifier);

    const thousandsLimit = 10000;

    return (value) => {
        const valueOrNully = isNully(value) ? format.nully : value;
        return typeof valueOrNully !== 'number'
            ? valueOrNully
            : Math.abs(valueOrNully) < thousandsLimit
            ? thousandsFormatter(valueOrNully)
            : numberFormatter(valueOrNully);
    };
}

export function validate(value, options) {
    return typeof value === 'number' && !isNully(value)
        ? {success: true, value}
        : reconcileNull(value, options);
}
