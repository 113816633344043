import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {groupBy, zip, isNil, last} from 'lodash';
import {getFormatter} from '../Number';
import './PivotTable.css';

import {piecewise, interpolateRgb} from 'd3-interpolate';

const CANVAS_WIDTH_COLORMAP = 256;
const CUTOFF_BRIGHTNESS = 255 / 2; // eslint-disable-line

function getBrightness(r, g, b) {
    /* See https://www.w3.org/TR/AERT/#color-contrast */
    return (r * 299 + g * 587 + b * 114) / 1000; // eslint-disable-line
}

function getRGB(str) {
    var match = str.match(
        /rgb?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
    );
    return match ? [match[1], match[2], match[3]] : [];
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
          ]
        : null;
}
/**
 * The Pivot Table component is used to power the Pivot Table DBE element.
 */
export default function PivotTable({
    id,
    data,
    axis_assignment,
    selectionOutput,
    title,
    format,
    setProps,
}) {
    /* workaround for parent stretching to fit our content */
    function viewportRef(elem) {
        if (elem) {
            elem.parentElement.classList.remove('height-auto');
        }
    }

    const {
        table,
        col_totals,
        row_totals,
        grand_total,
        table_colors,
        col_totals_colors,
        row_totals_colors,
        grand_total_color,
        colorscale,
    } = data;
    const rowHeaderDepth = Object.keys(axis_assignment.rows).length;
    const colHeaderDepth = Object.keys(axis_assignment.columns).length;
    // we only allow similar values in the entire array

    function getColors(colorscale) {
        var validated_colorscale = colorscale;

        if (colorscale[0].startsWith('var')) {
            const currStyles = getComputedStyle(
                document.querySelector('.ddk-container')
            );

            var css_colorscales = [];
            for (var i = 0; i < colorscale.length; i++) {
                var property = colorscale[i].slice(
                    'var('.length,
                    colorscale[i].length - 1
                );
                var color = currStyles.getPropertyValue(property);

                if (color === '') {
                    break;
                }
                css_colorscales.push(color);
            }
            validated_colorscale = css_colorscales;
        }
        return validated_colorscale;
    }

    const validatedColorscale = getColors(colorscale);

    const [colors, setColors] = useState(validatedColorscale);

    var updateColors = (colorscale) => {
        const newColors = getColors(colorscale);
        if (newColors !== colors) {
            setColors(newColors);
        }
    };
    const handleUpdateColors = () => {
        setTimeout(() => {
            updateColors(colorscale);
        }, 3);
    };

    useEffect(() => {
        const newColors = getColors(colorscale);
        if (newColors !== colors) {
            setColors(newColors);
        }

        // subscribe event
        window.addEventListener('dash-theme-update', handleUpdateColors);
        return () => {
            // unsubscribe event
            window.removeEventListener('dash-theme-update', handleUpdateColors);
        };
    }, [colorscale]);

    const colHeaders = getColHeaders(
        getNamesAndSpan(
            addName(table.columns),
            Object.keys(axis_assignment.columns)
        )
    );

    const rowNamesAndSpans = getNamesAndSpan(
        addName(table.index),
        Object.keys(axis_assignment.rows)
    );
    const rowLayers = getColHeaders(rowNamesAndSpans);

    function unique_dim_values(dimNames, dimValues) {
        let localDimValues = dimValues;
        if (!Array.isArray(dimValues[0])) {
            localDimValues = dimValues.map((val) => [val]);
        }

        return dimNames.reduce(
            (result, name, index) => ({
                ...result,
                [name]: [...new Set(localDimValues.map((row) => row[index]))],
            }),
            {}
        );
    }

    const dimension_values = {
        ...unique_dim_values(Object.keys(axis_assignment.rows), table.index),
        ...unique_dim_values(
            Object.keys(axis_assignment.columns),
            table.columns
        ),
    };

    function getDimensionCoordinates(rowIndex, colIndex) {
        function getPath(item) {
            return [
                ...item.dimensionPath,
                {dimension: item.dimension, value: item.value},
            ];
        }

        const rowPath = isNil(rowIndex)
            ? []
            : getPath(last(rowLayers)[rowIndex]);
        const colPath = isNil(colIndex)
            ? []
            : getPath(last(colHeaders)[colIndex]);
        return rowPath.concat(colPath);
    }

    function isCellSelected(rowIndex, colIndex) {
        var dims = getDimensionCoordinates(rowIndex, colIndex);

        return dims
            .map(({dimension, value}) =>
                selectionOutput[dimension]
                    ? selectionOutput[dimension].includes(value)
                    : true
            )
            .every((x) => x);
    }

    function areDimensionsFullySelected(dimensionNames) {
        return dimensionNames
            .map(
                (dimName) =>
                    isNil(selectionOutput[dimName]) ||
                    selectionOutput[dimName].length ===
                        dimension_values[dimName].length
            )
            .every((x) => x);
    }

    function isRowTotalSelected(rowIndex) {
        return (
            areDimensionsFullySelected(Object.keys(axis_assignment.columns)) &&
            isCellSelected(rowIndex, null)
        );
    }

    function isColTotalSelected(colIndex) {
        return (
            areDimensionsFullySelected(Object.keys(axis_assignment.rows)) &&
            isCellSelected(null, colIndex)
        );
    }

    function isGrandTotalSelected() {
        return areDimensionsFullySelected([
            ...Object.keys(axis_assignment.rows),
            ...Object.keys(axis_assignment.columns),
        ]);
    }

    const tableRows = zip(
        getRowHeaders(rowNamesAndSpans),
        zip(table.data, table_colors || []),
        zip(row_totals, row_totals_colors)
    );

    const canvas = document.createElement('canvas');
    canvas.width = CANVAS_WIDTH_COLORMAP;
    canvas.height = 10;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, CANVAS_WIDTH_COLORMAP, 0);

    const c0 = getComputedStyle(
        document.querySelector('.ddk-container')
    ).getPropertyValue('--background_page');

    const c1 = getComputedStyle(
        document.querySelector('.ddk-container')
    ).getPropertyValue('--body_text');

    gradient.addColorStop(0, c0);
    gradient.addColorStop(1, c1);

    ctx.fillStyle = c0;
    ctx.fillRect(0, 0, 1, 1);
    const c0Rgb = ctx.getImageData(0, 0, 1, 1).data;
    const c0Brightness = getBrightness(...c0Rgb);

    ctx.fillStyle = c1;
    ctx.fillRect(0, 0, 1, 1);
    const c1Rgb = ctx.getImageData(0, 0, 1, 1).data;
    const c1Brightness = getBrightness(...c1Rgb);

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    function getBackgroundStyle(value, color) {
        if (isNil(color) || isNil(value)) {
            return {};
        }

        let colorValue = color;
        if (typeof color === 'string' && color.startsWith('#')) {
            colorValue = hexToRgb(color);
        }

        const rgbColor = piecewise(interpolateRgb, colors)(colorValue);
        const [r, g, b] = getRGB(rgbColor);

        const brightness = getBrightness(r, g, b);

        const textColor = Array.isArray(colorValue)
            ? brightness < CUTOFF_BRIGHTNESS
                ? 'white'
                : 'black'
            : Math.abs(c0Brightness - brightness) >
              Math.abs(c1Brightness - brightness)
            ? c0
            : c1;

        return {
            color: textColor,
            backgroundColor: `rgb(${r}, ${g}, ${b})`,
        };
    }

    function removeEmptyLists(obj) {
        return Object.entries(obj).reduce(
            (result, [k, v]) => (v.length === 0 ? result : {...result, [k]: v}),
            {}
        );
    }

    function selectDimension(dimension, value, add) {
        const newState = add
            ? removeEmptyLists({
                  ...selectionOutput,
                  [dimension]:
                      selectionOutput[dimension] &&
                      selectionOutput[dimension].includes(value)
                          ? selectionOutput[dimension].filter(
                                (x) => x !== value
                            )
                          : [...(selectionOutput[dimension] || []), value],
              })
            : selectionOutput[dimension] &&
              selectionOutput[dimension].includes(value)
            ? {}
            : {[dimension]: [value]};

        setProps({selectionOutput: newState});
    }

    function selectDimensionPath(dimensionPath, add) {
        function isSelected(item) {
            return (
                selectionOutput[item.dimension] &&
                selectionOutput[item.dimension].includes(item.value)
            );
        }

        function addItem(item, obj) {
            return {
                ...obj,
                [item.dimension]: obj[item.dimension]
                    ? obj[item.dimension].includes(item.value)
                        ? obj[item.dimension]
                        : [...obj[item.dimension], item.value]
                    : [item.value],
            };
        }

        function removeItem(item, obj) {
            return removeEmptyLists(
                Object.entries(obj).reduce(
                    (result, [dimension, values]) => ({
                        ...result,
                        [dimension]:
                            dimension === item.dimension
                                ? values.filter((v) => v !== item.value)
                                : values,
                    }),
                    {}
                )
            );
        }

        const newState = add
            ? isSelected(last(dimensionPath))
                ? dimensionPath.reduce(
                      (result, item) => removeItem(item, result),
                      selectionOutput
                  )
                : dimensionPath.reduce(
                      (result, item) => addItem(item, result),
                      selectionOutput
                  )
            : dimensionPath.reduce(
                  (result, {dimension, value}) => ({
                      ...result,
                      [dimension]: [value],
                  }),
                  {}
              );

        setProps({selectionOutput: newState});
    }

    function selectCellDimensions(rowIndex, columnIndex, add) {
        selectDimensionPath(
            getDimensionCoordinates(rowIndex, columnIndex),
            add
        );
    }

    // TODO make this overwriteable
    format.locale = {
        symbol: ['$', ''],
        decimal: '.',
        group: ',',
        grouping: [3],
        percent: '%',
        separate_4digits: true,
    };
    const formatter = getFormatter(format);

    return (
        <div id={id} className="dash-pivot-table__viewport" ref={viewportRef}>
            {title && <div className="dash-pivot-table__title">{title}</div>}
            <table className="dash-pivot-table">
                <thead>
                    {Object.entries(axis_assignment.columns).map(
                        ([col_key, column], rowIndex) => (
                            <tr key={column}>
                                {rowIndex === 0 &&
                                    Object.keys(axis_assignment.rows).length !==
                                        0 && (
                                        <td
                                            colSpan={rowHeaderDepth}
                                            rowSpan={colHeaderDepth}
                                        />
                                    )}
                                <th className="dash-pivot-table__col-header">
                                    {column}
                                </th>
                                {colHeaders[rowIndex].map(
                                    (
                                        {
                                            name,
                                            value,
                                            key,
                                            span,
                                            selected,
                                            dimensionPath,
                                        },
                                        _index
                                    ) => (
                                        <th
                                            className={`dash-pivot-table__col-label ${
                                                selected
                                                    ? 'dash-pivot-table__col-label--selected'
                                                    : ''
                                            }`}
                                            colSpan={span}
                                            rowSpan={
                                                rowIndex ===
                                                    colHeaderDepth - 1 &&
                                                Object.keys(
                                                    axis_assignment.rows
                                                ).length !== 0
                                                    ? 2
                                                    : null
                                            }
                                            key={key}
                                            onClick={(e) =>
                                                value !== 'OTHER' &&
                                                value !== null &&
                                                selectDimension(
                                                    col_key,
                                                    value,
                                                    e.shiftKey
                                                )
                                            }
                                            onContextMenu={(e) => {
                                                selectDimensionPath(
                                                    [
                                                        ...dimensionPath,
                                                        {
                                                            dimension: column,
                                                            value,
                                                        },
                                                    ],
                                                    e.shiftKey
                                                );
                                                e.preventDefault();
                                            }}
                                        >
                                            {name}
                                        </th>
                                    )
                                )}
                                {rowIndex === 0 && (row_totals || grand_total) && (
                                    <th
                                        className="dash-pivot-table__total-label"
                                        rowSpan={colHeaderDepth + 1}
                                    >
                                        Total
                                    </th>
                                )}
                            </tr>
                        )
                    )}
                    <tr>
                        {Object.values(axis_assignment.rows).map((column) => (
                            <th
                                className="dash-pivot-table__row-header"
                                key={column}
                            >
                                {column}
                            </th>
                        ))}
                        {Object.values(axis_assignment.rows).length > 0 && (
                            <th className="dash-pivot-table__total-label" />
                        )}
                    </tr>
                </thead>
                <tbody>
                    {tableRows &&
                        tableRows.map(
                            (
                                [headers, contentAndColors, totalTuple],
                                rowIndex
                            ) => (
                                <tr key={rowIndex}>
                                    {headers &&
                                        headers.map(
                                            (
                                                {
                                                    name,
                                                    value,
                                                    span,
                                                    selected,
                                                    dimension,
                                                    dimensionPath,
                                                },
                                                index
                                            ) => (
                                                <th
                                                    key={index}
                                                    className={`dash-pivot-table__row-label ${
                                                        selected
                                                            ? 'dash-pivot-table__row-label--selected'
                                                            : ''
                                                    }`}
                                                    colSpan={
                                                        index ===
                                                            headers.length -
                                                                1 &&
                                                        axis_assignment.columns
                                                            .length !== 0
                                                            ? 2
                                                            : null
                                                    }
                                                    rowSpan={span}
                                                    onClick={(e) =>
                                                        value !== 'OTHER' &&
                                                        value !== null &&
                                                        selectDimension(
                                                            dimension,
                                                            value,
                                                            e.shiftKey
                                                        )
                                                    }
                                                    onContextMenu={(e) => {
                                                        selectDimensionPath(
                                                            [
                                                                ...dimensionPath,
                                                                {
                                                                    dimension,
                                                                    value,
                                                                },
                                                            ],
                                                            e.shiftKey
                                                        );
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    {name}
                                                </th>
                                            )
                                        )}
                                    {contentAndColors &&
                                        zip(
                                            contentAndColors[0],
                                            contentAndColors[1]
                                        ).map(([value, color], colIndex) => (
                                            <td
                                                key={colIndex}
                                                className={`dash-pivot-table__value ${
                                                    isCellSelected(
                                                        rowIndex,
                                                        colIndex
                                                    )
                                                        ? 'dash-pivot-table__value-selected'
                                                        : ''
                                                }`}
                                                style={getBackgroundStyle(
                                                    value,
                                                    color
                                                )}
                                                onDoubleClick={(e) =>
                                                    selectCellDimensions(
                                                        rowIndex,
                                                        colIndex,
                                                        e.shiftKey
                                                    )
                                                }
                                            >
                                                {formatter(value)}
                                                <div className="dash-pivot-table__value-overlay"></div>
                                            </td>
                                        ))}
                                    {row_totals &&
                                        (([total, colorTotal]) => (
                                            <td
                                                className={`dash-pivot-table__value ${
                                                    isRowTotalSelected(rowIndex)
                                                        ? 'dash-pivot-table__value-selected'
                                                        : ''
                                                }`}
                                                style={getBackgroundStyle(
                                                    total,
                                                    colorTotal
                                                )}
                                            >
                                                {formatter(total)}
                                                <div className="dash-pivot-table__value-overlay"></div>
                                            </td>
                                        ))(totalTuple)}
                                </tr>
                            )
                        )}
                    {col_totals && (
                        <tr>
                            <th
                                className="dash-pivot-table__total-label"
                                colSpan={rowHeaderDepth + 1}
                            >
                                {Object.values(axis_assignment.rows).length >
                                    0 && 'Total'}
                            </th>
                            {zip(col_totals, col_totals_colors).map(
                                ([value, color], index) => (
                                    <td
                                        key={index}
                                        className={`dash-pivot-table__value ${
                                            isColTotalSelected(index)
                                                ? 'dash-pivot-table__value-selected'
                                                : ''
                                        }`}
                                        style={getBackgroundStyle(value, color)}
                                    >
                                        {formatter(value)}
                                        <div className="dash-pivot-table__value-overlay"></div>
                                    </td>
                                )
                            )}
                            <td
                                className={`dash-pivot-table__value ${
                                    isGrandTotalSelected()
                                        ? 'dash-pivot-table__value-selected'
                                        : ''
                                }`}
                                style={getBackgroundStyle(
                                    grand_total,
                                    grand_total_color ? grand_total_color : null
                                )}
                            >
                                {formatter(grand_total)}
                                <div className="dash-pivot-table__value-overlay"></div>
                            </td>
                        </tr>
                    )}
                    {!col_totals && grand_total && (
                        <tr>
                            <th
                                className="dash-pivot-table__total-label"
                                colSpan={rowHeaderDepth + 1}
                            >
                                Total
                            </th>
                            <td
                                className={`dash-pivot-table__value ${
                                    isGrandTotalSelected()
                                        ? 'dash-pivot-table__value-selected'
                                        : ''
                                }`}
                                style={getBackgroundStyle(
                                    grand_total,
                                    grand_total_color ? grand_total_color : null
                                )}
                            >
                                {formatter(grand_total)}
                                <div className="dash-pivot-table__value-overlay"></div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );

    function addName(list) {
        return Array.isArray(list[0])
            ? list.map((tuple) =>
                  tuple.map((value) => ({name: String(value), value}))
              )
            : list.map((value) => ({name: String(value), value}));
    }

    function getNamesAndSpan(
        headerTuples,
        dimensions,
        path = '',
        dimensionPath = []
    ) {
        function isSelected(value) {
            return (
                selectionOutput[dimensions[0]] &&
                selectionOutput[dimensions[0]].includes(value) &&
                dimensionPath
                    .map(({dimension, value}) =>
                        selectionOutput[dimension]
                            ? selectionOutput[dimension].includes(value)
                            : true
                    )
                    .every((x) => x)
            );
        }

        return !(Array.isArray(headerTuples[0]) && headerTuples[0].length > 0)
            ? headerTuples.map((item) => ({
                  name: item.name,
                  value: item.value,
                  key: `${path}/${item.name}`,
                  dimensionPath,
                  selected: isSelected(item.value),
                  dimension: dimensions[0],
              }))
            : Object.entries(groupBy(headerTuples, ([{name}]) => name)).reduce(
                  (accum, [name, tuples]) => [
                      ...accum,
                      {
                          name,
                          value: tuples[0][0].value,
                          key: `${path}/${name}`,
                          span: tuples.length,
                          dimensionPath,
                          selected: isSelected(tuples[0][0].value),
                          dimension: dimensions[0],
                          subColumns:
                              tuples[0].length > 1 &&
                              getNamesAndSpan(
                                  tuples.map(([_, ...rest]) => rest),
                                  dimensions.slice(1),
                                  `${path}/${name}`,
                                  [
                                      ...dimensionPath,
                                      {
                                          dimension: dimensions[0],
                                          value: tuples[0][0].value,
                                      },
                                  ]
                              ),
                      },
                  ],
                  []
              );
    }
}

PivotTable.defaultProps = {};

PivotTable.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The data
     */
    data: PropTypes.object,

    /**
     * The axis assignment
     */
    axis_assignment: PropTypes.object,

    /**
     * The selection output
     */
    selectionOutput: PropTypes.object,

    /**
     * The title
     */
    title: PropTypes.string,

    /**
     * The number format
     */
    format: PropTypes.any,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};

function getColHeaders(namesAndSpan) {
    const result = [];
    function concatLevel(data, level) {
        result[level] = (result[level] || []).concat(data);
        data.forEach(
            ({subColumns}) => subColumns && concatLevel(subColumns, level + 1)
        );
    }
    concatLevel(namesAndSpan, 0);
    return result;
}

function getRowHeaders(namesAndSpan) {
    const paddedRows = getColHeaders(namesAndSpan).map((row) =>
        row.flatMap((item) => [item, ...Array((item.span || 1) - 1)])
    );
    return zip(...paddedRows).map((row) =>
        row.filter((item) => typeof item !== 'undefined')
    );
}
