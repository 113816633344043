import {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * The Dashboard State maintains DBE state on the client. This component is
 * meant to be created via the `DashboardEngine.make_state_and_canvas()`
 * function, rather than directly. The `elements` and `connection_params`
 * properties of this component are the key for capturing dashboard layouts in
 * callbacks. `connection_params` is also writeable, to pass parameters to a
 * Connection Provider.
 */
export default class DashboardState extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return null;
    }
}

DashboardState.defaultProps = {
    connection_params: '',
    elements: [],
};

DashboardState.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The list of elements
     */
    elements: PropTypes.array,

    /**
     * The connection params to access the data on the backend
     */
    connection_params: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};
