import React, {Component} from 'react';
import PropTypes from 'prop-types';

// React Grid layout
import {Responsive, WidthProvider} from 'react-grid-layout';

// eslint-disable-next-line
const ResponsiveGridLayout = WidthProvider(Responsive);

// Material UI components
import {Fab} from '@material-ui/core';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CheckIcon from '@material-ui/icons/Check';
import CodeIcon from '@material-ui/icons/Code';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import constants from '../constants';

const clone = function (obj) {
    return JSON.parse(JSON.stringify(obj));
};

import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = sortableElement(({child, className, overlay, style}) => (
    <div style={style} className={className}>
        {child} {overlay}
    </div>
));

const SortableContainer = sortableContainer(({children, className}) => {
    return (
        <div className={className} style={{height: '100%'}}>
            {children}
        </div>
    );
});

const DragHandle = sortableHandle(() => (
    <Fab size="small" component="div" color="action">
        <OpenWithIcon />
    </Fab>
));

const UNSELECT_CARD = -2;
const UNSELECT_CHILD = -3;

const loadingCard = (
    <div className="placeholder hourglasswaiting">
        <HourglassEmptyIcon fontSize="large" />
    </div>
);

function coerceArray(x) {
    return Array.isArray(x) ? x : [x];
}

function nextChildrenIndex(arrangement) {
    const childrenIndex = arrangement.grid.lg
        .map((d) => d.children)
        .flat()
        .sort((a, b) => a - b);
    let next = Math.max(...childrenIndex, -1) + 1;
    for (var i = 0; i < childrenIndex.length; i++) {
        if (childrenIndex[i] !== i) {
            next = i;
            break;
        }
    }

    return next;
}

/**
 * The Dashboard Canvas is the primary component in Dashboard Engine. This
 * component is meant to be created via the
 * `DashboardEngine.make_state_and_canvas()` function, rather than directly. The
 * `arrangement` property of this component is the key for capturing dashboard
 * layouts in callbacks.
 */
export default class DashboardCanvas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,

            // contextual menu state
            selectedCard: -1,
            lastClickedCard: -1,
            translateX: 0,
            translateY: 0,
            scale: 1,
            theme: window.dashTheme,
        };

        this.cardRefs = [];

        this.bindEvents = this.bindEvents.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.generateCardsDOM = this.generateCardsDOM.bind(this);
        this.focusOnCard = this.focusOnCard.bind(this);
        this.focusOnChildren = this.focusOnChildren.bind(this);
        this.focusOnNewlyAddedChild = this.focusOnNewlyAddedChild.bind(this);
        this.addCard = this.addCard.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.addChildren = this.addChildren.bind(this);
        this.removeChildren = this.removeChildren.bind(this);
    }

    bindEvents() {
        window.addEventListener('dash-theme-update', this);
    }

    /*
     * handleEvent is a special method that allows this class to
     * be passed (as `this`) in place of an event handler. This is used in conjunction with
     * `window.addEventListener` that listens to the `dash-theme-update`. See more:
     *    - https://developer.mozilla.org/en-US/docs/Web/API/EventListener/handleEvent)
     *    - https://metafizzy.co/blog/this-in-event-listeners/
     */
    handleEvent() {
        this.setState({theme: window.dashTheme});
    }

    componentWillUnmount() {
        window.removeEventListener('dash-theme-update', this);
    }

    componentDidMount() {
        this.bindEvents();
        this.handleEvent();
    }

    focusOnNewlyAddedChild(element) {
        if (!element) {
            return;
        }
        const {cardIndex, childIndex} = {...element?.props};
        const parent = element?.node?.parentElement;
        if (!this.cardRefs[cardIndex] && cardIndex === this.cardRefs.length) {
            this.cardRefs.push(parent);
        }
        if (this.state.lastChildIndex === childIndex) {
            this.focusOnChildren(childIndex);
            this.focusOnCard(cardIndex, parent);
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     Object.entries(this.props).forEach(
    //         ([key, val]) =>
    //             prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    //     );
    //     if (this.state) {
    //         Object.entries(this.state).forEach(
    //             ([key, val]) =>
    //                 prevState[key] !== val &&
    //                 console.log(`State '${key}' changed`)
    //         );
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {
        const {selected_children} = this.props;

        if (this.state) {
            Object.entries(this.state).forEach(
                ([key, val]) =>
                    prevState[key] !== val &&
                    console.log(
                        `State '${key}' changed from ${prevState[key]} to ${val}`
                    )
            );
        }

        if (
            selected_children === -1 &&
            selected_children !== prevProps.selected_children
        ) {
            this.focusOnCard(UNSELECT_CHILD);
        }
    }

    focusOnChildren(i) {
        this.props.setProps({selected_children: i});
    }

    focusOnCard(cardIndex, element) {
        var newSelectCard = cardIndex;
        var previousScale = this.state.scale;
        var newScale = previousScale;
        var translateX = this.state.translateX;
        var translateY = this.state.translateY;

        // Viewport size
        const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );

        if (cardIndex >= 0) {
            let itemDOMRect;
            if (element) {
                itemDOMRect = element.getBoundingClientRect();
            } else {
                itemDOMRect = this.cardRefs[cardIndex].getBoundingClientRect();
            }

            // Inferred original size of element (ie. without transform)
            var width = itemDOMRect.width / previousScale;
            var height = itemDOMRect.height / previousScale;

            // Get original relative coordinates
            var x = itemDOMRect.x - translateX;
            var y = itemDOMRect.y - translateY;

            // Size of menu
            const relativeMenuWidth = constants.menuDrawerWidth;
            var menuWidth = relativeMenuWidth * vw;

            var availableWidth = (1 - relativeMenuWidth) * vw;
            var availableHeight = vh;
            var margin =
                constants.focusOnCardMargin *
                Math.min(availableWidth, availableHeight);

            var round = (N) => Math.ceil(N / 10) * 10;

            newScale = Math.min(
                (availableWidth - 2 * margin) / width,
                (availableHeight - 2 * margin) / height,
                1
            );
            translateX = round(
                menuWidth + (availableWidth - width * newScale) / 2 - x
            );
            translateY = round((availableHeight - height * newScale) / 2 - y);
        } else if (cardIndex === -1) {
            newScale = 1 - constants.menuDrawerWidth;
            translateX = constants.menuDrawerWidth * vw;
            translateY = 0;
        } else {
            newScale = 1;
            translateX = 0;
            translateY = 0;
        }

        // Unselecting a child should not deselect the card if it has children
        if (cardIndex === UNSELECT_CHILD) {
            const {arrangement} = this.props;
            const {selectedCard} = this.state;

            // Check if there are children (ie. more than 1 child)
            if (arrangement.grid.lg[selectedCard]?.children.length > 1) {
                newSelectCard = selectedCard;
            }
        }

        this.setState({
            selectedCard: newSelectCard,
            translateX,
            translateY,
            scale: newScale,
        });
    }

    generateCardsDOM() {
        // console.log('generateCardsDOM');
        const {setProps, arrangement, selected_children} = this.props;
        const selectedChildren = selected_children;
        const {editing, selectedCard, lastClickedCard} = this.state;
        const {
            focusOnCard,
            focusOnChildren,
            removeCard,
            addChildren,
            removeChildren,
            focusOnNewlyAddedChild,
        } = this;

        const selectCard = (cardIndex) =>
            this.setState({selectedCard: cardIndex});
        const clickCard = (cardIndex) =>
            this.setState({lastClickedCard: cardIndex});
        const children = coerceArray(this.props.children);

        return arrangement.grid.lg.map(function (description, cardIndex) {
            const key = description.i;
            const card_content = coerceArray(description.children);

            const onSortEnd = ({oldIndex, newIndex}) => {
                console.log('onSortEnd');
                const newArrangement = clone(arrangement);
                newArrangement.grid.lg[cardIndex].children = arrayMove(
                    card_content,
                    oldIndex,
                    newIndex
                );
                setProps({arrangement: newArrangement});
            };

            var isLoading =
                Math.max(...card_content) > children.length - 1 ||
                card_content.some((i) => children[i] === null);

            return (
                <div
                    key={key}
                    onClick={() => {
                        clickCard(cardIndex);
                    }}
                    style={{zIndex: cardIndex === lastClickedCard ? 1 : null}}
                >
                    <SortableContainer
                        onSortEnd={onSortEnd}
                        className="block card ddk-card controls"
                        lockAxis="y"
                        useDragHandle
                    >
                        {isLoading && loadingCard}
                        {!isLoading &&
                            card_content.map(function (i, index) {
                                const derivedClassList = ['card--content'];
                                const derivedInlineStyle = {
                                    position: 'relative',
                                };
                                const child = children[i];
                                const childType =
                                    child?.props?._dashprivate_layout?.type;

                                if (childType === 'DataTable') {
                                    derivedInlineStyle.height = `calc(100% / ${card_content.length})`;
                                }

                                if (
                                    childType === 'Graph' ||
                                    childType === 'DataTable' ||
                                    (childType === 'Div' &&
                                        child?.props?._dashprivate_layout?.props
                                            .className === 'placeholder')
                                ) {
                                    derivedClassList.push('height-fill');
                                } else {
                                    derivedClassList.push('height-auto');
                                }

                                const overlay = editing &&
                                    selectedCard === cardIndex &&
                                    selectedChildren !== i && (
                                        <div className="nested-overlayed-menu">
                                            <div className="contextual-icons">
                                                <Fab
                                                    size="small"
                                                    component="div"
                                                    color="action"
                                                    aria-label="edit"
                                                    onClick={() => {
                                                        focusOnChildren(i);
                                                        focusOnCard(cardIndex);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </Fab>

                                                <DragHandle></DragHandle>

                                                <Fab
                                                    size="small"
                                                    component="div"
                                                    color="secondary"
                                                    aria-label="removeChildren"
                                                    onClick={() => {
                                                        removeChildren(
                                                            cardIndex,
                                                            i
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    );

                                return (
                                    <SortableItem
                                        key={`item-${index}`}
                                        ref={focusOnNewlyAddedChild}
                                        className={derivedClassList.join(' ')}
                                        index={index}
                                        child={child}
                                        childIndex={i}
                                        cardIndex={cardIndex}
                                        overlay={overlay}
                                        style={derivedInlineStyle}
                                    ></SortableItem>
                                );
                            })}
                    </SortableContainer>

                    {/* Side buttons */}
                    {editing &&
                        selectedCard === cardIndex &&
                        selectedChildren === -1 && (
                            <div className="card-fab">
                                <Fab
                                    component="div"
                                    color="action"
                                    aria-label="close"
                                    onClick={() => {
                                        focusOnCard(UNSELECT_CARD);
                                    }}
                                >
                                    <CheckIcon />
                                </Fab>

                                <Fab
                                    component="div"
                                    color="action"
                                    aria-label="add"
                                    onClick={() => addChildren(cardIndex)}
                                >
                                    <AddIcon />
                                </Fab>
                            </div>
                        )}

                    {editing && !isLoading && selectedCard !== cardIndex && (
                        <div
                            className={
                                selectedCard >= 0
                                    ? 'overlayed-menu active'
                                    : 'overlayed-menu'
                            }
                        >
                            <div className="contextual-icons">
                                <Fab
                                    className="dashboard-engine-element-edit"
                                    component="div"
                                    color="action"
                                    aria-label="edit"
                                    onClick={() => {
                                        if (card_content.length === 1) {
                                            focusOnChildren(card_content[0]);
                                            focusOnCard(cardIndex);
                                        } else {
                                            // If many children, select card but don't zoom on it
                                            focusOnChildren(-1);
                                            selectCard(cardIndex);
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </Fab>

                                {card_content.length <= 1 && (
                                    <Fab
                                        component="div"
                                        color="action"
                                        aria-label="addChildren"
                                        onClick={() => addChildren(cardIndex)}
                                    >
                                        <AddIcon />
                                    </Fab>
                                )}

                                <Fab
                                    component="div"
                                    color="action"
                                    aria-label="drag"
                                    className="dragHandle"
                                >
                                    <OpenWithIcon />
                                </Fab>

                                <Fab
                                    component="div"
                                    color="secondary"
                                    aria-label="delete"
                                    onClick={() => {
                                        removeCard(cardIndex);
                                    }}
                                >
                                    <DeleteIcon />
                                </Fab>
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    }

    addChildren(selectedCard) {
        const {setProps, arrangement} = this.props;

        const arrangementCopy = clone(arrangement);
        const next = nextChildrenIndex(arrangementCopy);

        let cardChildren = arrangementCopy.grid.lg[selectedCard].children;
        if (cardChildren && Array.isArray(cardChildren)) {
            cardChildren.push(next);
        } else {
            cardChildren = [next];
        }

        setProps({arrangement: arrangementCopy});
        this.setState({lastChildIndex: next});
    }

    removeChildren(cardIndex, childrenIndex) {
        const {setProps, arrangement} = this.props;

        const arrangementCopy = clone(arrangement);
        const cardChildren = arrangementCopy.grid.lg[cardIndex].children;
        const index = cardChildren.indexOf(childrenIndex);
        if (index > -1) {
            cardChildren.splice(index, 1);
        }

        if (cardChildren.length <= 1) {
            this.focusOnCard(UNSELECT_CARD);
        }
        this.focusOnChildren(-1);
        setProps({arrangement: arrangementCopy});
    }

    addCard() {
        // console.log('addCard');
        const defaultWidth = 4;
        const fallbackHeight = 2;
        const {setProps, arrangement} = this.props;

        const arrangementCopy = clone(arrangement);
        if (!arrangementCopy.grid.lg) {
            arrangementCopy.grid = {lg: []};
        }
        const lastY = Math.max(
            ...arrangementCopy.grid.lg.map((d) => d.y).concat(0)
        );
        const lastXonlastY = Math.max(
            ...arrangementCopy.grid.lg
                .filter((d) => d.y === lastY)
                .map((d) => d.x + d.w)
                .concat(0)
        );
        let x, y;
        // eslint-disable-next-line
        if (lastXonlastY + defaultWidth <= 12) {
            x = lastXonlastY;
            y = lastY;
        } else {
            x = 0;
            y = Math.max(
                ...arrangementCopy.grid.lg.map((d) => d.h + d.y).concat(0)
            );
        }
        const lastH = (
            arrangementCopy.grid.lg.slice(-1)[0] || {h: fallbackHeight}
        ).h;

        const lastI = Math.max(
            ...arrangementCopy.grid.lg.map((d) => parseInt(d.i, 10)),
            -1
        );

        const next = nextChildrenIndex(arrangementCopy);

        arrangementCopy.grid.lg.push({
            i: (lastI + 1).toString(),
            w: defaultWidth,
            h: lastH,
            x,
            y,
            children: [next],
        });

        setProps({arrangement: arrangementCopy});
        this.setState({lastChildIndex: next});
    }

    removeCard(i) {
        const {setProps, arrangement} = this.props;

        const arrangementCopy = clone(arrangement);
        if (!arrangementCopy.grid.lg) {
            arrangementCopy.grid = {lg: []};
        } else {
            arrangementCopy.grid.lg.splice(i, 1);
        }

        if (this.cardRefs[i]) {
            this.cardRefs.splice(i, 1);
        }

        const selectedCard = this.state.selectedCard;
        if (selectedCard >= 0 && i <= selectedCard) {
            this.setState({selectedCard: selectedCard - 1});
        }
        return setProps({
            arrangement: arrangementCopy,
        });
    }

    onBreakpointChange(breakpoint) {
        console.log(`breakpoint: ${breakpoint}`);
    }

    render() {
        // const d = new Date();
        // console.log(`Render ${d.getTime()}`);
        const {
            setProps,
            arrangement,
            editable,
            selected_children,
            inspect_n_clicks,
            inspectable,
        } = this.props;

        // Convert arrangement to RGL
        const RGLLayout = arrangement.grid;

        const {selectedCard, theme} = this.state;

        var showContextualMenu;
        if (selected_children >= 0) {
            showContextualMenu = true;

            // Deactivate the built-in editor for now
            showContextualMenu = false;
        } else {
            showContextualMenu = false;
        }

        // console.log(arrangement, children);
        const {scale, translateX, translateY} = this.state;
        var transform = `translate(${translateX || 0}px, ${
            translateY || 0
        }px) scale(${scale})`;

        const themeCardMargin = parseInt(theme.card_margin, 10);

        // Editor mode
        const editing = this.state.editing;

        const addCard = this.addCard;

        return (
            <div
                className="dashboard-engine-canvas"
                style={{
                    position: 'relative',
                    zIndex:
                        editing && selectedCard >= 0
                            ? constants.zIndexBelowDashErrorCard
                            : null,
                }}
            >
                {editing && selectedCard >= 0 && (
                    <div className="focused-backdrop" />
                )}

                <div
                    style={{
                        transformOrigin: 'top left',
                        transform: transform,
                        transition: 'transform 0.25s ease-in',
                    }}
                >
                    <ResponsiveGridLayout
                        layouts={RGLLayout}
                        onLayoutChange={(currentLayout, newArrangement) => {
                            const copyarrangement = clone(arrangement);

                            for (
                                var i = 0;
                                i < arrangement.grid.lg.length;
                                i++
                            ) {
                                // Keep list of children intact
                                newArrangement.lg[i].children =
                                    copyarrangement.grid.lg[i].children;
                            }
                            copyarrangement.grid = newArrangement;
                            setProps({arrangement: copyarrangement});
                        }}
                        breakpoints={{
                            lg: parseInt(theme.breakpoint_stack_blocks, 10),
                            small:
                                parseInt(theme.breakpoint_stack_blocks, 10) - 1,
                        }}
                        onBreakpointChange={this.onBreakpointChange}
                        className="layout"
                        // TODO: read from arrangement prop
                        cols={{lg: 12, small: 1}}
                        rowHeight={
                            arrangement.rowHeight || constants.defaultRowHeight
                        }
                        // in RGL, margin is an array provided as [left & right, top & bottom] in px
                        margin={[themeCardMargin, themeCardMargin]}
                        preventCollision={
                            'preventCollision' in arrangement
                                ? arrangement.preventCollision
                                : constants.defaultPreventCollision
                        }
                        verticalCompact={
                            'verticalCompact' in arrangement
                                ? arrangement.verticalCompact
                                : constants.defaultVerticalCompact
                        }
                        resizeHandles={['s', 'se']}
                        // WidthProvider option
                        measureBeforeMount={true}
                        transformScale={scale}
                        isDraggable={editing}
                        isResizable={editing}
                        draggableHandle=".dragHandle"
                    >
                        {this.generateCardsDOM()}
                    </ResponsiveGridLayout>
                </div>

                {/* Main canvas action buttons */}
                {editable && !showContextualMenu && (
                    <div className="fab">
                        {editing && (
                            <Fab
                                component="div"
                                color="action"
                                aria-label="addCard"
                                onClick={addCard}
                            >
                                <AddIcon />
                            </Fab>
                        )}
                        {editing && inspectable && (
                            <Fab
                                component="div"
                                color="action"
                                aria-label="showConfig"
                                onClick={() => {
                                    this.setState({
                                        inspect_n_clicks: inspect_n_clicks + 1,
                                    });
                                    this.props.setProps({
                                        inspect_n_clicks: inspect_n_clicks + 1,
                                    });
                                }}
                            >
                                <CodeIcon />
                            </Fab>
                        )}
                        <Fab
                            id="dashboard-engine-canvas-edit"
                            component="div"
                            color="action"
                            aria-label="close"
                            onClick={() => {
                                this.focusOnCard(UNSELECT_CARD);
                                this.setState({
                                    editing: !editing,
                                });
                                this.props.setProps({selected_children: -1});
                            }}
                        >
                            {editing ? <CheckIcon /> : <EditIcon />}
                        </Fab>
                    </div>
                )}
            </div>
        );
    }
}

DashboardCanvas.defaultProps = {
    editable: false,
    selected_children: -1,
    children: [],
    arrangement: {
        grid: {lg: []},
    },
    inspectable: true,
    inspect_n_clicks: 0,
};

DashboardCanvas.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * Whether the canvas is editable or not
     */
    editable: PropTypes.bool,

    /**
     * The children of this component
     */
    children: PropTypes.node,

    /**
     * The arrangement for the children
     */
    arrangement: PropTypes.object,

    /**
     * The element being edited
     */
    selected_children: PropTypes.number,

    /**
     * Whether the inspect button appears or not
     */
    inspectable: PropTypes.bool,

    /**
     * The number of times the inspect button has been clicked
     */
    inspect_n_clicks: PropTypes.number,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};
