const constants = {
    defaultRowHeight: 80,
    defaultVerticalCompact: true,
    defaultPreventCollision: false,
    menuDrawerWidth: 0.3,
    focusOnCardMargin: 0.1,

    // z-index below dash-error-card which is 1100
    zIndexBelowDashErrorCard: 1099,
};
export default constants;
