import {Component} from 'react';
import PropTypes from 'prop-types';

// Material UI components
import {Drawer, Grid, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ESC_KEY_CODE = 27;

import constants from '../constants';
const {menuDrawerWidth} = constants;

/**
 * The Dashboard Editor is used in conjunction with the Canvas.
 */
export default class DashboardEditor extends Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === ESC_KEY_CODE) {
            this.props.setProps({selected_element: -1});
        }
    }
    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    render() {
        const {setProps, selected_element, children} = this.props;
        const showContextualMenu = selected_element > -1;

        function closeButton() {
            return (
                <Grid item xs={12}>
                    <div
                        style={{
                            position: 'fixed',
                            left: `calc(${menuDrawerWidth * 100}vw - 26px)`,
                            top: '5px',
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setProps({selected_element: -1});
                            }}
                        >
                            <CloseIcon fontSize="medium" />
                        </IconButton>
                    </div>
                </Grid>
            );
        }
        return (
            <Drawer
                open={showContextualMenu}
                anchor="left"
                variant="persistent"
                classes={{paper: 'dbe-editor-menu-paper'}}
            >
                <div className="dbe-editor-menu">
                    <Grid container spacing={0}>
                        {closeButton()}
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
        );
    }
}

DashboardEditor.defaultProps = {
    children: [],
    selected_element: -1,
};

DashboardEditor.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The children of this component
     */
    children: PropTypes.node,

    /**
     * The element being edited
     */
    selected_element: PropTypes.number,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};
